import { Customer, CustomerAddress } from '@lambda/apis/src/customer/types';
import { CustomerAddressType } from '@lambda/apis/src/customer/enum';
import { DefaultValuesOption } from '@stripe/stripe-js';
import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import { CheckoutT } from '@lambda/apis/src/checkout/types';
import { isEmpty } from 'lodash-es';
import { NAVIGATION } from './constants';
import settings from '@/settings';
import { deleteCartLines, updateCartLines } from '../cart';
import { UpdateCartParams } from '../use-cart';
import { CheckoutStep } from './enums';

export const allowedCountries =
  settings.store !== 'reebelo-dev' ? [settings.country_code] : undefined;

/**
 * Phone number is optional for addresses
 * @param address
 * @returns
 */
export const isAddressValid = (
  address?: CustomerAddress | undefined,
): boolean => {
  let valid = !!(
    address &&
    address.firstName &&
    address.lastName &&
    address.address1 &&
    address.city &&
    address.country &&
    address.postalCode &&
    address.state
  );

  if (['reebelo-nz'].includes(settings.store)) {
    // NZ doesn't have states
    valid = !!(
      address &&
      address.firstName &&
      address.lastName &&
      address.address1 &&
      address.city &&
      address.country &&
      address.postalCode
    );
  }

  if (['quista'].includes(settings.store)) {
    // SG doesn't have states or city validation
    valid = !!(
      address &&
      address.firstName &&
      address.lastName &&
      address.address1 &&
      address.country &&
      address.postalCode
    );
  }

  console.log({ address, valid }, 'Determined if address is valid');

  return valid;
};

/**
 * Phone number and address2 are optional for addresses
 * @param address1
 * @param address2
 * @returns
 */
export const addressesAreEqual = (
  address1: CustomerAddress,
  address2: CustomerAddress,
) => {
  const equal =
    address1.firstName === address2.firstName &&
    address1.lastName === address2.lastName &&
    address1.address1 === address2.address1 &&
    address1.city === address2.city &&
    address1.country === address2.country &&
    address1.state === address2.state &&
    address1.postalCode === address2.postalCode &&
    (address1.phone === address2.phone || !address1.phone || !address2.phone) &&
    (address1.address2 === address2.address2 ||
      !address1.address2 ||
      !address2.address2);

  console.log(
    { address1, address2, equal },
    'Determined if addresses are equal',
  );

  return equal;
};

export const generatePageTitle = (
  step?: CheckoutStep,
  error?: string | null,
  processing?: boolean,
) => {
  if (!step) return `Loading - Reebelo ${settings.country_code} - Checkout`;

  const currentStepLabel = NAVIGATION.find((nav) => nav.value === step)?.label;

  return `${processing ? 'Processing - ' : ''} ${
    error != null ? 'Error - ' : ''
  } ${currentStepLabel} - Reebelo ${settings.country_code} - Checkout`;
};

export enum CountryToCode {
  'United States' = 'US',
  'Canada' = 'CA',
  'Australia' = 'AU',
  'Singapore' = 'SG',
  'New Zealand' = 'NZ',
}

export const initializeAddress = (
  checkout: CheckoutT | undefined,
  customer: Customer | undefined,
  type: CustomerAddressType,
) => {
  const attr =
    type === CustomerAddressType.Shipping
      ? 'shippingAddress'
      : 'billingAddress';

  const obj = isEmpty(checkout?.[attr]) ? customer : checkout;

  const country = () => {
    if (
      obj?.[attr]?.country &&
      Object.keys(CountryToCode).includes(obj?.[attr]?.country ?? '')
    )
      return CountryToCode[obj?.[attr]?.country as keyof typeof CountryToCode];

    return obj?.[attr]?.country ?? '';
  };

  return {
    firstName: obj?.[attr]?.firstName || '',
    lastName: obj?.[attr]?.lastName || '',
    address1: obj?.[attr]?.address1 || '',
    address2: obj?.[attr]?.address2 || '',
    city: obj?.[attr]?.city || '',
    country: country(),
    state: obj?.[attr]?.state || '',
    postalCode: obj?.[attr]?.postalCode || '',
    phone: obj?.[attr]?.phone || '',
  };
};

export const generateDefaultValuesOption = (
  customer: Customer,
): DefaultValuesOption => ({
  billingDetails: {
    name: `${customer.billingAddress?.firstName} ${customer.billingAddress?.lastName}`,
    email: customer.email,
    phone: customer.billingAddress?.phone,
    address: {
      country: customer.billingAddress?.country,
      postal_code: customer.billingAddress?.postalCode,
      state: customer.billingAddress?.state,
      city: customer.billingAddress?.city,
      line1: customer.billingAddress?.address1,
      line2: customer.billingAddress?.address2 || undefined,
    },
  },
});

export const generateLineItemQuantity = (
  lineItem: CartLine,
  outOfStockLineItems: CartLine[],
) => {
  const title = lineItem.merchandise.product.title.toLowerCase();

  if (title.includes('reebelocare') || title.includes('express shipping')) {
    const productFor = lineItem.attributes.find(
      (attr) => attr.key === 'For',
    )?.value;

    if (productFor) {
      const matchedProduct = outOfStockLineItems.find(
        (item) =>
          `[${item.merchandise.sku}] ${item.merchandise.product.title}` ===
          productFor,
      );

      if (matchedProduct != null) {
        return [
          matchedProduct.quantity,
          matchedProduct.merchandise.quantityAvailable,
        ];
      }
    }
  }

  return [lineItem.quantity, lineItem.merchandise.quantityAvailable];
};

export const updateCartLineItems = async (
  lineItems: CartLine[],
  cartId: string,
) => {
  const toBeRemoved: string[] = [];
  const toBeUpdated: UpdateCartParams = [];

  lineItems.forEach((lineItem) => {
    const [, availableQuantity] = generateLineItemQuantity(lineItem, lineItems);

    if (availableQuantity < 1) {
      toBeRemoved.push(lineItem.id);
    } else {
      const updatedLineItem = {
        attributes: lineItem.attributes,
        id: lineItem.id,
        quantity: availableQuantity,
      };

      toBeUpdated.push(updatedLineItem);
    }
  });

  await updateCartLines(toBeUpdated, cartId);
  await deleteCartLines(toBeRemoved, cartId);
};

// isEmailSubscribed is pre-checked in all stores except CA
export const initializeIsEmailSubscribed = () => {
  if (['reebelo-ca'].includes(settings.store)) return false;

  return true;
};
