import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import { ReebeloConditions } from '@lambda/reebelo';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import shopify from '@/lib/shopify';
import settings from '@/settings';
import { CartItemAttribute, UpdateCartParams } from './use-cart';
import { formatWarrantyInfo, getWarrantyInfoByCategoryPks } from './warranty';

const getLastestProductQuantityUsingHandle = async (handle: string) => {
  const response = await shopify.fetch({
    query: `query ($handle: String!) {
              product(handle: $handle) {
                variants(first:1){ edges { node {
                  id availableForSale quantityAvailable
                } } }
              }
            }`,
    variables: { handle },
  });
  const { product } = response;

  return {
    quantityAvailable:
      product?.variants?.edges?.[0]?.node?.quantityAvailable || 0,
    availableForSale: product?.variants?.edges?.[0]?.node?.availableForSale,
  };
};

export const SHOPIFY_CART_QUERY = `
id checkoutUrl
attributes { key value }
discountCodes { code applicable }
estimatedCost { totalAmount { amount } subtotalAmount { amount } totalTaxAmount { amount } }
lines(first: 10) { edges { node {
  id quantity attributes { key value }
  merchandise { ... on ProductVariant {
      id sku quantityAvailable title
      image { url }
      priceV2 { amount }
      compareAtPriceV2 {amount}
      product { title, tags, vendor, onlineStoreUrl, handle, productType}
  } }
} } }`;

export const deleteCartLines = async (
  lineIds: Array<string>,
  cartId: string,
) => {
  const response = await shopify.fetchSafe({
    query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                  cart { ${SHOPIFY_CART_QUERY} }
                  userErrors { field message }
                }
              }`,
    variables: { cartId, lineIds },
  });

  return response?.cartLinesRemove.cart;
};

export const updateCartLines = async (
  lines: UpdateCartParams,
  cartId: string,
) => {
  const response = await shopify.fetchSafe({
    query: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
              cartLinesUpdate(cartId: $cartId, lines: $lines) {
                cart { ${SHOPIFY_CART_QUERY} }
                userErrors { field message }
              }
            }`,
    variables: { cartId, lines },
  });

  return response?.cartLinesUpdate.cart;
};

export const findOutOfStockCartLineItems = async (lineItems: CartLine[]) => {
  const toBeRemovedCartLineItems: string[] = [];

  const unavailableLineItems = await Promise.all(
    lineItems.map(async (lineItem) => {
      const { title } = lineItem.merchandise.product;
      const { quantity } = lineItem;

      if (
        title.toLowerCase().indexOf('reebelocare') > -1 ||
        title.toLowerCase().indexOf('express shipping') > -1 ||
        title.toLowerCase().indexOf("reebelo's gift card") > -1
      )
        return undefined;

      const { quantityAvailable, availableForSale } =
        await getLastestProductQuantityUsingHandle(
          lineItem.merchandise.product.handle,
        );

      if (quantityAvailable - quantity < 0 || !availableForSale) {
        return {
          id: lineItem.id,
          sku: lineItem.merchandise.sku,
          vendor: lineItem.merchandise.product.vendor,
        };
      }

      return undefined;
    }),
  );

  /*
   * RT-4875
   * Check for the merchandise to be removed
   */
  if (unavailableLineItems.filter(Boolean).length > 0) {
    unavailableLineItems.forEach((unavailableLineItem) => {
      if (unavailableLineItem != null) {
        // Add the to be removed merchandise into the array
        toBeRemovedCartLineItems.push(unavailableLineItem.id);
        const offerKey = `${settings.store}#${unavailableLineItem.vendor}#${unavailableLineItem.sku}`;

        // Find out reebelocare / express shipping item to be removed
        lineItems.forEach((lineItem) => {
          const { title } = lineItem.merchandise.product;

          if (
            title.toLowerCase().indexOf('reebelocare') > -1 ||
            title.toLowerCase().indexOf('express shipping') > -1
          ) {
            const { attributes } = lineItem;
            const tobeRemove = attributes.some(
              (attribute: CartItemAttribute) =>
                attribute.key === 'ID' && attribute.value === offerKey,
            );

            if (tobeRemove) toBeRemovedCartLineItems.push(lineItem.id);
          }
        });
      }
    });
  }

  return toBeRemovedCartLineItems;
};

export const generateCartWarrantyText = async ({
  conditions,
  categoryPks,
}: {
  conditions: string[];
  categoryPks: string[];
}) => {
  const checkCategoryPks = async () => {
    const warrantyInfoArray = await getWarrantyInfoByCategoryPks(categoryPks);

    if (!warrantyInfoArray?.length) {
      return {
        warrantyLength: settings.warranty_month,
        warrantyUnit: 'month',
        eligible: true,
      };
    }
    const ineligibleWarranties = warrantyInfoArray.filter(
      (warrantyInfo) => !warrantyInfo?.eligible,
    );

    const eligibleWarranties = warrantyInfoArray.filter(
      (warrantyInfo) => warrantyInfo?.eligible,
    );

    if (ineligibleWarranties.length > 0 && eligibleWarranties.length > 0) {
      return {
        warrantyLength: settings.warranty_month,
        warrantyUnit: 'month',
        eligible: false,
        mixedCart: true,
      };
    }

    if (eligibleWarranties.length === 0) {
      return {
        warrantyLength: settings.warranty_month,
        warrantyUnit: 'month',
        eligible: true,
      };
    }

    // Normalize warranty lengths to days
    const convertToDays = (length?: number, unit?: string): number => {
      if (!unit || !length) return 0;

      switch (unit) {
        case 'day':
          return length;
        case 'month':
          return length * 30; // Approximation: 1 month = 30 days
        case 'year':
          return length * 365; // Approximation: 1 year = 365 days
        default:
          return 0;
      }
    };

    const firstWarranty = eligibleWarranties[0];
    const allSame = eligibleWarranties.every(
      (warranty) =>
        warranty.warrantyLength === firstWarranty.warrantyLength &&
        warranty.warrantyUnit === firstWarranty.warrantyUnit,
    );

    if (allSame) {
      // If all warranties are the same, return the warranty length and unit
      return {
        warrantyLength: firstWarranty.warrantyLength,
        warrantyUnit: firstWarranty.warrantyUnit,
        eligible: true,
      };
    }
    // If they differ, find the longest warranty in terms of days
    const longestWarranty = eligibleWarranties.reduce((longest, current) => {
      const longestInDays = convertToDays(
        longest?.warrantyLength,
        longest.warrantyUnit,
      );
      const currentInDays = convertToDays(
        current?.warrantyLength,
        current.warrantyUnit,
      );

      return currentInDays > longestInDays ? current : longest;
    });

    return {
      warrantyLength: longestWarranty.warrantyLength,
      warrantyUnit: longestWarranty.warrantyUnit,
      eligible: true,
      mixedCart: eligibleWarranties.length > 1,
    };
  };
  const checkItemCondition = (
    streamlinedCondition: string,
    method: 'some' | 'every' = 'some',
  ) => conditions[method]((condition) => condition === streamlinedCondition);

  const allItemsAreBrandNew = checkItemCondition(
    ReebeloConditions.BrandNew,
    'every',
  );

  if (
    !['reebelo-us', 'quista', 'reebelo-ca', 'reebelo-dev'].includes(
      settings.store,
    )
  )
    return `${settings.warranty_month} Months Warranty`;

  if (allItemsAreBrandNew) return '12 Months Warranty';

  const warrantyInfo = await checkCategoryPks();

  const formattedWarrantyInfo = formatWarrantyInfo(
    warrantyInfo,
    warrantyInfo.mixedCart,
  );

  if (formattedWarrantyInfo) return `${formattedWarrantyInfo} warranty`;

  if (['quista', 'reebelo-dev'].includes(settings.store))
    return '3 months warranty';

  return `${settings.warranty_month} Months Warranty`;
};

export const generateCategoriesConditions = (lineItems: CartLine[]) => {
  const conditions: string[] = [];

  lineItems.forEach((item) => {
    const extractedTags = extractTags(item.merchandise.product.tags);

    conditions.push(extractedTags.condition ?? '');
  });

  const categoryPks = lineItems
    .map((item) => {
      const categoryPk = item.attributes.find(
        (attribute) => attribute.key === '_category_pk',
      )?.value;

      return categoryPk;
    })
    .filter((categoryPk): categoryPk is string => Boolean(categoryPk));

  return {
    categoryPks,
    conditions,
  };
};
