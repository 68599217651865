import { NavigationT } from './types';
import settings from '@/settings';
import returnIcon from '@/public/icons/collection/category/return.svg';
import deliveryIcon from '@/public/icons/collection/category/stars.svg';
import warrantyIcon from '@/public/icons/collection/category/warranty.svg';
import { CheckoutStep } from './enums';
import { generateCartWarrantyText } from '../cart';

export const generateUsps = async ({
  categoryPks,
  conditions,
}: {
  categoryPks: string[];
  conditions: string[];
}) => [
  {
    label: `${settings.free_return_days} days Money Back Guarantee`,
    icon: returnIcon,
  },
  { label: 'Free Shipping & Returns', icon: deliveryIcon },
  {
    label: await generateCartWarrantyText({
      categoryPks,
      conditions,
    }),
    icon: warrantyIcon,
  },
];

export const NAVIGATION: NavigationT[] = [
  { label: 'Cart', value: CheckoutStep.Cart, handle: 'cart' },
  { label: 'Shipping', value: CheckoutStep.Shipping },
  { label: 'Payment', value: CheckoutStep.Payment },
];

export const SURVEY_OPTIONS = [
  { value: 'search-engine', label: 'Search Engine (Google, Bing, etc.)' },
  { value: 'meta', label: 'Meta (Facebook, Instagram, etc.)' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'x-twitter', label: 'X/Twitter' },
  { value: 'radio-podcast', label: 'Radio/Podcast' },
  { value: 'cable-ss-tvs', label: 'Cable/Streaming Services & TVs' },
  { value: 'news-articles', label: 'News/Articles (MacWorld, PCMag, etc.)' },
  { value: 'review-sites', label: 'Review Sites (Trustpilot, BBB, etc.)' },
  {
    value: 'discount-coupon-sites',
    label: 'Discount/Coupon Sites (Rakuten, Slickdeals, etc.)',
  },
  {
    value: 'ooh-ads',
    label: 'Out-of-home Ads. (Train, Bus, Billboard, etc.)',
  },
  {
    value: 'friends-family-colleagues',
    label: 'Friends, Family or Colleagues.',
  },
  { value: 'other', label: 'Other' },
];
